import(/* webpackMode: "eager" */ "/Users/maro/ws/hx/hx-core/apps/compass-cx/app/globals.css");
;
import(/* webpackMode: "eager" */ "/Users/maro/ws/hx/hx-core/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.0.4_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@_iwlnnsx2tb6tf3hf7ilrvhklum/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/Users/maro/ws/hx/hx-core/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.0.4_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@_iwlnnsx2tb6tf3hf7ilrvhklum/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/Users/maro/ws/hx/hx-core/node_modules/.pnpm/@next+third-parties@15.1.6_next@15.0.4_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@_iwlnnsx2tb6tf3hf7ilrvhklum/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/Users/maro/ws/hx/hx-core/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/Users/maro/ws/hx/hx-core/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/.pnpm/geist@1.3.1_next@15.0.4_@babel+core@7.26.7_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0_/node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
